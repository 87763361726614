import React, { Component } from "react";
import Typical from "react-typical";

class Art extends Component {
  render() {
    return (
      <div id="art" style={{ marginTop: "70px", marginBottom: "30px" }}>
        <div style={{ margin: "auto", width: "80%" }}>
          <div className="card" style={{ background: "#efe1bd" }}>
            <div className="card-header">
              <h1>
                ABOUT ME <Typical steps={["(as an artist)"]} />
              </h1>
            </div>
            <div
              className="card-body font-trebuchet text-justify ml-3 mr-3"
              style={{
                height: "auto",
                fontSize: "150%",
                lineHeight: "200%",
              }}
            >
              <span style={{ paddingLeft: "24px" }}>
                I grew up in the Bay Area, CA and will completed my degrees in
                Studio Arts, Computer Science, and Mathematics in May 2021 from
                the University of Rochester. Upon graduation, I relocated to
                Boston, MA to start my career as a Software Engineer. My work
                has been shown in multiple exhibitions at the University of
                Rochester’s Sage Art Center.
              </span>
              <br />
              <span style={{ paddingLeft: "24px" }}>
                My senior thesis exhibition distort showcased paintings of
                figures submerged in water. In my works, the water acts as a
                boundary between realism and abstraction, clarity and
                deformation.
              </span>
              <br />
              <span style={{ paddingLeft: "24px" }}>
                Much of my past work is inspired by found objects. In these
                works, I play with the importance of material and how the medium
                and marks communicate the stories I am trying to tell. This
                relation of material to meaning is the driving force behind much
                of my past works and still inspires my current explorations as I
                shift my interest towards painted form.
              </span>
              <br />
            </div>
          </div>
        </div>

        <div
          className="col-lg-12"
          style={{
            cursor: "pointer",
            marginTop: "20px",
            background: "#70abaf",
          }}
        >
          {/* <div className="card" style={{ background: "#70abaf" }}> */}
          <h1 className="section-title" style={{ color: "white" }}>
            EXHIBITIONS AND MISC WORK
          </h1>

          <div className="row center mx-auto">
            <div className="col-lg-4 center">
              <a href="/#/thesis" rel="noopener noreferrer">
                <div className="polaroid">
                  <span>
                    <img
                      height="290px"
                      src="images/portfolio/thesis/multiple.jpg"
                      alt="Avatar placeholder"
                    />
                    <br />
                    <br />
                    <h1>DISTORT</h1>
                  </span>
                </div>
              </a>
            </div>

            <div className="col-lg-4 center">
              <a href="/#/memories" rel="noopener noreferrer">
                <div className="polaroid">
                  <span>
                    <img
                      height="290px"
                      src="images/portfolio/memories/single.jpg"
                      alt="Avatar placeholder"
                    />
                    <br />
                    <br />
                    <h1>MEM</h1>
                  </span>
                </div>
              </a>
            </div>

            <div className="col-lg-4 center">
              <a href="/#/other" rel="noopener noreferrer">
                <div className="polaroid">
                  <span>
                    <img
                      height="290px"
                      src="images/portfolio/other/p1.jpg"
                      alt="Avatar placeholder"
                    />
                    <br />
                    <br />
                    <h1>MISCELLANEOUS</h1>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Art;
