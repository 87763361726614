import React, { Component } from "react";
import Typical from "react-typical";
import Skills from "./Skills";

const social = [
  {
    name: "github",
    url: "https://github.com/lizapressman",
    class: "fab fa-github",
  },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/in/lizapressman/",
    class: "fab fa-linkedin-in",
  },
];

class About extends Component {
  render() {
    var networks = social.map(function (network) {
      return (
        <a href={network.url} target="_blank" rel="noopener noreferrer">
          <i
            style={{
              color: "#70abaf",
              padding: "20px",
              fontSize: "30px",
            }}
            className={network.class}
          ></i>
        </a>
      );
    });

    return (
      <div id="about">
        <div className="col-md-12">
          <div className="row center mx-auto">
            <div className="col-md-4 center">
              <div className="polaroid">
                <span>
                  <img
                    height="250px"
                    src="images/myProfile.jpg"
                    alt="Avatar placeholder"
                  />
                  <br />
                  <br />
                  <Typical
                    steps={["HELLO :))", 1500, "I'M LIZA", 1500]}
                    wrapper="h1"
                  />
                  <div className="social-links">{networks}</div>
                </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-12">
                <div className="card" style={{ background: "#efe1bd" }}>
                  <div className="card-header" style={{ textAlign: "center" }}>
                    <h1>ABOUT ME</h1>
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "150%",
                      lineHeight: "200%",
                    }}
                  >
                    <span style={{ paddingLeft: "24px" }}>
                      I currently live in Somerville, MA and work at Datadog as
                      a Software Engineer. I work on the Serverless App team
                      which is primarily a frontend team! I graduated from the
                      University of Rochester in 2021 as a triple major in
                      Computer Science, Mathematics, and Studio Arts. You can
                      view my past and present work experience{" "}
                      <a href="/#/experience">here</a> or take a look at my{" "}
                      <a href="/LizaPressmanResume.pdf">resume</a>.
                    </span>
                    <br />
                    <span style={{ paddingLeft: "24px" }}>
                      While not working, I love to stay creative with art! Feel
                      free to check out my <a href="/#/art">work</a>. I do a lot
                      of painting but love to play with random material as well.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Skills />
      </div>
    );
  }
}

export default About;
